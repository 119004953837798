<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <Week
        @change_date="change_date"
        :outer_index="0"
        :begin_num="0"
        :data="data"
      />
      <div v-if="recent_remind">
        <div class="page-alert-timeline" v-if="recent_remind.reminds">
          <div
            class="page-alert-timeline-item"
            :class="index === recent_remind.reminds.length - 1 ? 'last' : ''"
            v-for="(item, index) in recent_remind.reminds"
            :key="index"
          >
            <div class="item-avatar">
              <img
                :src="
                  item.avatar_url ||
                  'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epibNpKIwo4zFYnIBEKenlgXLVicNjBYc179V6A4nbVlayT1TldnYlWWOrv0bOriaUAEXdtPQ5XGcSoA/132'
                "
                alt=""
              />
              <p>
                {{ item.name }}<br />
                <span>{{ item.relationship_label }}</span>
              </p>
            </div>
            <div class="item-circle"></div>
            <div class="item-content">
              <div class="arrow-left"></div>
              <div class="content">
                <div class="content-header">
                  <h3>{{ item.title }}</h3>
                  <span
                    >{{ format_date(item.remind_time, "M月dd日") }}
                    {{ remove_second(item.send_time) }}</span
                  >
                </div>
                <div class="content-bodyer">
                  <div v-if="item.remind_type === 1" class="des">
                    <div v-for="(it, idx) in item.drugs" :key="idx">
                      <p>{{ it.name }}/{{ it.freq_name }}</p>
                      {{ it.usage }}
                    </div>
                  </div>
                  <div v-if="item.remind_type === 5 || item.remind_type === 6" class="des">
                    <p>{{item.content}}</p> 
                    <!-- 门诊 -->
                  </div>
                  <div v-else class="des" v-html="item.content"></div>
                  <div class="btn" @click="jump_url(item)">详情</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="no-data" v-if="!recent_remind && !loading">
        <img width="150" src="../../assets/img/no-data.png" />
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import { get_list } from "./service";
import Week from "./Week";
import { format_date } from "../../utils/format";
export default {
  data() {
    return {
      loading: false,
      data: null,
      recent_remind: null,
      active_index: 0,
    };
  },
  computed: {},
  components: {
    Week,
  },
  created() {
    this.get_reminds();
  },
  methods: {
    format_date,
    remove_second(v) {
      return v.substring(0, 7);
    },
    async get_reminds() {
      this.loading = true;
      try {
        const { data } = await get_list();
        this.data = data;
        this.change_recent();
      } finally {
        this.loading = false;
      }
    },
    change_recent() {
      this.data.sort(function(a, b) {
        return a.remind_date - b.remind_date
      })
      this.data.forEach(el=>{
        el.reminds.sort(function(a, b) {
          return parseInt(a.send_time.substring(2,4)) - parseInt(b.send_time.substring(2,4))
        })
      })
      this.recent_remind =
        this.active_index < 0 ? null : this.data[this.active_index];
    },
    change_date(data) {
      for (let i = 0; i < this.data.length; i++) {
        if (
          new Date(data).toDateString() ===
          new Date(this.data[i].remind_date).toDateString()
        ) {
          this.active_index = i;
          break;
        } else {
          this.active_index = -1;
        }
      }
      this.change_recent();
    },
    jump_url(item) {
      if(item.remind_type === 4) {
        this.$router.push({
          path: item.remind_url,
          query: {
            title: item.title,
            content: item.content
          }
        })
      } else {
        this.$router.push({
          path: '/alert-detail',
          query: {
            type: item.remind_type,
            id: item.id
          }
        })
      }
    }
  },
};
</script>

<style scoped>
.g_page_box {
  background: #f8f8f8;
}

.page-alert-timeline {
  margin: 30px 12px 0 12px;
  position: relative;
}

.page-alert-timeline-item::after {
  content: "";
  height: 120px;
  width: 1px;
  background: #e2e2e2;
  position: absolute;
  left: 58px;
  margin-top: 24px;
}

.last::after {
  width: 0px;
}

.page-alert-timeline-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.item-content {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.arrow-left {
  position: relative;
  top: 16px;
  left: 4px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  margin-left: 4px;
  font-size: 0;
  line-height: 0;
  transform: rotate(-90deg);
}

.content {
  border-radius: 10px;
  padding: 10px;
  flex: 1;
  min-height: 60px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
}

.content-bodyer {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.des {
  flex: 1;
  font-size: 12px;
  line-height: 17px;
  color: #2b303f;
  max-height: 34px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.btn {
  background: #008fff;
  padding: 6px 10px;
  border-radius: 24px;
  color: #fff;
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #2b303f;
  }

  & span {
    font-size: 14px;
    line-height: 20px;
    color: #008fff;
  }
}

.item-avatar {
  text-align: right;
  width: 46px;
  font-size: 12px;
  line-height: 14px;
  color: #2b303f;

  & img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px #fff solid;
    margin-bottom: 6px;
  }

  & span {
    display: inline-block;
  }
}
.item-circle {
  width: 14px;
  height: 14px;
  z-index: 9;
  background: #008fff;
  border-radius: 50%;
  margin-left: 6px;
  margin-top: 14px;
  border: 2px #fff solid;
  -webkit-box-shadow: 00px 0px 10px rgba($color: #000000, $alpha: 0.1);
  box-shadow: 00px 0px 10px rgba($color: #000000, $alpha: 0.1);
}

.no-data {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #565656;
  & img {
    margin-bottom: 10px;
  }
}
</style>
