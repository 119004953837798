import api from '../../utils/api'
// 登录
export async function get_list () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: [
  //         {
  //           remind_date: today,
  //           reminds: [
  //             {
  //               title: '用药提醒',
  //               id: 1,
  //               remind_time: today,
  //               content: '二甲双胍0.5mg x2粒 餐前<br>胰岛素增敏剂0.5g x2粒 餐后',
  //               name: '勇恒',
  //               url: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epibNpKIwo4zFYnIBEKenlgXLVicNjBYc179V6A4nbVlayT1TldnYlWWOrv0bOriaUAEXdtPQ5XGcSoA/132',
  //               relationship: '2',
  //               relationship_label: '夫妻',
  //               remind_type: 1,
  //               drugs: [
  //                 {
  //                   name: '头孢拉丁缓释片0.5mg*20',
  //                   freq_name: '口服',
  //                   usage: '每日二次 一次0.5mg/片',
  //                 },
  //                 {
  //                   name: '缓释片0.5mg*20',
  //                   freq_name: '注射',
  //                   usage: '每日二次 一次0.5mg/瓶',
  //                 },
  //               ]
  //             },
  //             {
  //               title: '复诊提醒',
  //               id: 2,
  //               remind_time: today,
  //               content: '请带好健康卡前往罗湖医院<br>就诊',
  //               name: '勇恒',
  //               url: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epibNpKIwo4zFYnIBEKenlgXLVicNjBYc179V6A4nbVlayT1TldnYlWWOrv0bOriaUAEXdtPQ5XGcSoA/132',
  //               relationship: '2',
  //               relationship_label: '夫妻',
  //               remind_type: 3,
  //             },
  //             {
  //               title: '就诊提醒',
  //               id: 2,
  //               remind_time: today,
  //               content: '请带好健康卡前往罗湖医院<br>就诊',
  //               name: '勇恒',
  //               url: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epibNpKIwo4zFYnIBEKenlgXLVicNjBYc179V6A4nbVlayT1TldnYlWWOrv0bOriaUAEXdtPQ5XGcSoA/132',
  //               relationship: '2',
  //               relationship_label: '夫妻',
  //               remind_type: 2,
  //             },
  //             {
  //               title: '肠镜检查提醒',
  //               id: 3,
  //               remind_time: today,
  //               content: '请核实排泄物是否清澈？排泄物如何<br>符合场景检查标准？',
  //               name: '勇恒',
  //               url: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epibNpKIwo4zFYnIBEKenlgXLVicNjBYc179V6A4nbVlayT1TldnYlWWOrv0bOriaUAEXdtPQ5XGcSoA/132',
  //               relationship: '2',
  //               relationship_label: '夫妻',
  //               remind_type: 4,
  //             },
  //           ],
  //         },
  //         {
  //           remind_date: clone_date.setDate(clone_date.getDate() + 2),
  //           reminds: [
  //             {
  //               title: '用药提醒2',
  //               id: 4,
  //               remind_time: clone_date.setDate(clone_date.getDate() + 2),
  //               content: '二甲双胍0.5mg x2粒 餐前<br>胰岛素增敏剂0.5g x2粒 餐后',
  //               name: '勇恒2',
  //               url: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epibNpKIwo4zFYnIBEKenlgXLVicNjBYc179V6A4nbVlayT1TldnYlWWOrv0bOriaUAEXdtPQ5XGcSoA/132',
  //               relationship: '2',
  //               relationship_label: '夫妻',
  //               remind_type: 1,
  //               drugs: [
  //                 {
  //                   name: '头孢拉丁缓释片0.5mg*20',
  //                   freq_name: '口服',
  //                   usage: '每日二次 一次0.5mg/片',
  //                 },
  //                 {
  //                   name: '缓释片0.5mg*20',
  //                   freq_name: '注射',
  //                   usage: '每日二次 一次0.5mg/瓶',
  //                 },
  //               ]
  //             },
  //             {
  //               title: '复诊提醒2',
  //               id: 5,
  //               remind_time: clone_date.setDate(clone_date.getDate() + 2),
  //               content: '请带好健康卡前往罗湖医院<br>就诊',
  //               name: '勇恒2',
  //               url: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epibNpKIwo4zFYnIBEKenlgXLVicNjBYc179V6A4nbVlayT1TldnYlWWOrv0bOriaUAEXdtPQ5XGcSoA/132',
  //               relationship: '2',
  //               relationship_label: '夫妻',
  //               remind_type: 2,
  //             },
  //             {
  //               title: '肠镜检查提醒2',
  //               id: 6,
  //               remind_time: clone_date.setDate(clone_date.getDate() + 2),
  //               content: '请核实排泄物是否清澈？排泄物如何<br>符合场景检查标准？',
  //               name: '勇恒2',
  //               url: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epibNpKIwo4zFYnIBEKenlgXLVicNjBYc179V6A4nbVlayT1TldnYlWWOrv0bOriaUAEXdtPQ5XGcSoA/132',
  //               relationship: '2',
  //               relationship_label: '夫妻',
  //               remind_type: 4,
  //             },
  //           ],
  //         },
  //         {
  //           remind_date: clone_date.setDate(clone_date.getDate() + 5),
  //           reminds: [
  //             {
  //               title: '用药提醒3',
  //               id: 7,
  //               remind_time: clone_date.setDate(clone_date.getDate() + 5),
  //               content: '二甲双胍0.5mg x2粒 餐前<br>胰岛素增敏剂0.5g x2粒 餐后',
  //               name: '勇恒3',
  //               url: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epibNpKIwo4zFYnIBEKenlgXLVicNjBYc179V6A4nbVlayT1TldnYlWWOrv0bOriaUAEXdtPQ5XGcSoA/132',
  //               relationship: '2',
  //               relationship_label: '夫妻',
  //               remind_type: 1,
  //               drugs: [
  //                 {
  //                   name: '头孢拉丁缓释片0.5mg*20',
  //                   freq_name: '口服',
  //                   usage: '每日二次 一次0.5mg/片',
  //                 },
  //                 {
  //                   name: '缓释片0.5mg*20',
  //                   freq_name: '注射',
  //                   usage: '每日二次 一次0.5mg/瓶',
  //                 },
  //               ]
  //             },
  //             {
  //               title: '复诊提醒3',
  //               id: 8,
  //               remind_time: clone_date.setDate(clone_date.getDate() + 5),
  //               content: '请带好健康卡前往罗湖医院<br>就诊',
  //               name: '勇恒3',
  //               url: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epibNpKIwo4zFYnIBEKenlgXLVicNjBYc179V6A4nbVlayT1TldnYlWWOrv0bOriaUAEXdtPQ5XGcSoA/132',
  //               relationship: '2',
  //               relationship_label: '夫妻',
  //               remind_type: 2,
  //             },
  //             {
  //               title: '肠镜检查提醒3',
  //               id: 9,
  //               remind_time: clone_date.setDate(clone_date.getDate() + 5),
  //               content: '请核实排泄物是否清澈？排泄物如何<br>符合场景检查标准？',
  //               name: '勇恒3',
  //               url: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epibNpKIwo4zFYnIBEKenlgXLVicNjBYc179V6A4nbVlayT1TldnYlWWOrv0bOriaUAEXdtPQ5XGcSoA/132',
  //               relationship: '2',
  //               relationship_label: '夫妻',
  //               remind_type: 4,
  //             },
  //           ],
  //         }
  //       ]
  //       // }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v1/mp/reminds')
}


