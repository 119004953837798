<template>
  <!-- 周 -->
  <div class="week" v-if="list">
    <div class="week_inner">
      <div
        class="item"
        @click="change_date(item, idx)"
        v-for="(item, idx) in list"
        :key="idx"
      >
        <div class="days">周{{ week_arr[item.days] }}</div>
        <div class="date" :class="item.active? 'active' : ''">
          {{ item.date }}
        </div>
      </div>
    </div>
    <div class="img_inner">
      <img
        src="../../assets/img/bottom-arrow.png"
        alt=""
        @click.stop="show = true"
      />
    </div>
    <van-calendar
      v-model="show"
      v-if="minDate"
      position="top"
      :default-date="default_date"
      :min-date="minDate"
      :max-date="maxDate"
      color="#1989fa"
      @confirm="onConfirm"
      :show-confirm="false"
      :style="{ height: '500px' }"
      :formatter="formatter"
    />
  </div>
</template>
<script>
import { format_date } from "../../utils/format";
export default {
  data() {
    return {
      list: [],
      week_arr: ["日", "一", "二", "三", "四", "五", "六"],
      show: false,
      minDate: "",
      maxDate: "",
      default_date: null,
      today: new Date(),
    };
  },
  props: {
    outer_index: {
      default: () => {},
      type: Number,
    },
    begin_num: {
      default: () => {},
      type: Number,
    },
    data: {
      default: ()=>{},
      type: Array,
    },
  },
  computed: {},
  watch: {
    today() {},
    data(v) {
      let list = []
      v.forEach(item=>{
        this.list.forEach(it=>{
          if(new Date(item.remind_date).toDateString() === new Date(it.time_str).toDateString()) {
            it.active = true
            list.push(it)
          }
        })
      })
      if(list.length) {
        this.default_date = list[0].time_str
      }
    },
  },
  mounted() {
    
  },
  created() {
    this.get_min_max();
    this.get_list();
  },
  methods: {
    format_date,
    get_min_max() {
      let day = new Date();
      day.setDate(day.getDate() + this.begin_num);
      this.minDate = day;
      let day2 = new Date();
      day2.setDate(day.getDate() + 120);
      this.maxDate = day2;
    },
    get_list() {
      this.active_index = this.outer_index;
      this.list = [];
      for (let i = this.begin_num; i <= this.begin_num + 120; i++) {
        const tmr = this.today.clone();
        tmr.setDate(tmr.getDate() + i);
        this.list.push({
          days: tmr.getDay(),
          date: tmr.getDate(),
          time_str: tmr,
          active: false,
        });
      }
      
    },
    change_date(item) {
      if(!item.active) return
      this.default_date = item.time_str;
      this.$emit("change_date", this.default_date);
    },
    onConfirm(date) {
      this.default_date = date.clone();
      this.show = false;
      this.$emit("change_date", this.default_date);
    },
    formatter(day) {
      this.data.forEach(item=>{
        if(new Date(item.remind_date).toDateString() === new Date(day.date).toDateString()){
          day.className = 'else_class';
        }
      })
      
      return day;
    },
  },
};
</script>
<style scoped>
.week {
  background: #fff;
  height: 88px;
  padding: 15px 0;
}

.week_inner {
  display: -webkit-box;
  align-items: center;
  justify-content: space-around;
  overflow-x: auto;
}

.item {
  height: 100%;
  width: calc(100% / 7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.days,
.date {
  font-size: 12px;
  font-weight: 500;
  color: #8b959e;
  margin-bottom: 10px;
}

.date {
  color: #292929;
  width: 19px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  border-radius: 10px;

  &.active {
    background: #008fff;
    color: #fff;
  }
}

.img_inner {
  text-align: center;
}

>>>.van-calendar__day.else_class {
  position: relative;
  color: #ff0000;
  
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 3px;
    background: #ff0000;
    position: absolute;
    right: 15px;
    top: 20px;
  }
}
</style>

